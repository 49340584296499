import React, {FC, useContext, useState} from 'react';
import Button from "./Button";
import {Icons} from "../helpers/Icons";
import reminderStyles from "./css/Reminder.module.css";
import styles from './css/Form.module.css';
import {useTranslation} from "react-i18next";
import {formValidation} from "../helpers/helperFunctions";
import Context from "../helpers/Context";
import {remindHostAboutVisitorByVisitorId} from "../helpers/apiEndPoints";
import {v4} from "uuid";
import {useMsal} from "@azure/msal-react";

interface IProps
{
    visitorId: string;
    disabled?: boolean;
}

const Reminder: FC<IProps> = ({visitorId, disabled = false}) =>
{
    const {t} = useTranslation();
    const {notificationDispatch} = useContext(Context);
    const {accounts, instance} = useMsal();

    const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
    const [hostEmail, setHostEmail] = useState<string | null>(null);

    const handleReminder = async () =>
    {
        if(!hostEmail)
        {
            notificationDispatch({
                type: "ADD_NOTIFICATION",
                payload:
                    {
                        id: v4(),
                        type: "error",
                        message: "Host email cannot be null"
                    }
            });

            setIsFormVisible(false);
            return ;
        }

        await remindHostAboutVisitorByVisitorId(visitorId, hostEmail, {accounts: accounts
        , instance: instance}, notificationDispatch).then(result =>
        {
            if(result)
                notificationDispatch({
                    type: "ADD_NOTIFICATION",
                    payload:
                        {
                            id: v4(),
                            type: "success",
                            message: "Host was succesfully notified"
                        }
                })
        });

        setIsFormVisible(false);
    }

    return (
        <div>
            <Button btnSize="btn-sm" btnStyle="optional" onClick={() =>
            {
                setIsFormVisible(true);
            }} disabled={disabled}>{t("lists.remind")}{Icons.remind}</Button>


            {isFormVisible &&
                <div className={styles.edit_form_container} onClick={() => setIsFormVisible(current => !current)}
                     onTouchEnd={() => setIsFormVisible(current => !current)}>
                    <div className={styles.edit_form} onClick={e => e.stopPropagation()}
                         onTouchEnd={e => e.stopPropagation()}>
                        <form className={`${styles.form} + ${styles.form_edit}`}
                              onSubmit={e => formValidation(e, [
                                  {
                                      inputName: t("app.email"),
                                      input: hostEmail,
                                      skip: false
                                  }], notificationDispatch, handleReminder)}>

                            <label>
                                {t("app.email")}*
                                <input type="email"
                                       required={true}
                                       onChange={e => setHostEmail(e.target.value)}/>
                            </label>

                            <input type="submit" value={t("lists.remind") as string}/>
                        </form>
                    </div>
                </div>
            }
        </div>
    );
}

export default Reminder;