import {FaBell, FaEdit, FaTimes, FaTrash, FaUserCircle} from 'react-icons/fa';
import {GiHamburgerMenu} from "react-icons/gi";
import {RiArrowDownSLine, RiArrowLeftSLine, RiArrowRightSLine, RiArrowUpSLine} from "react-icons/ri";
import {TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted} from "react-icons/ti";
import {BsCheckLg, BsExclamationLg, BsFillExclamationTriangleFill} from "react-icons/bs";

export const Icons =
{
    edit: <FaEdit />,
    delete: <FaTrash />,
    remind: <FaBell />,
    hamburgerMenu: <GiHamburgerMenu />,
    arrowLeft: <RiArrowLeftSLine />,
    arrowRight: <RiArrowRightSLine />,
    arrowDown: <RiArrowDownSLine />,
    arrowUp: <RiArrowUpSLine />,
    descArrow: <TiArrowSortedDown />,
    ascArrow: <TiArrowSortedUp />,
    unsorted: <TiArrowUnsorted />,
    close: <FaTimes />,
    error: <BsFillExclamationTriangleFill />,
    success: <BsCheckLg />,
    warning: <BsExclamationLg />,
    user: <FaUserCircle />
}